import { Popover, Typography } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import DashboardOutlined from '@mui/icons-material/DashboardOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FolderOutlined from '@mui/icons-material/FolderOutlined';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ShareIcon from '@mui/icons-material/Share';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';

import AdminContentContainer from 'components/Containers/AdminContentContainer';
import MenuListItem from 'components/List/MenuListItem';
import MenuListItemIcon from 'components/List/MenuListItemIcon';
import MenuTextItem from 'components/List/MenuTextItem';
import ProjectsSidebarContent from 'containers/Projects/ProjectList/ProjectsSidebarContent';
import FinancialPlanSidebarContent from 'views/FinancialPlanPage/FinancialPlan/SidebarContent';
import AnalysisListItem from './AnalysisListItem';
import MessagesItem from './MessagesItem';

import { openDialog } from 'containers/Marketplace/dialogSlice';
import { toggleProjectsSidebar } from 'containers/Projects/slices';
import { getCompanyPath } from 'utils/auth/getCompanyPath';
import { regexCompany } from 'utils/constants/regex';
import { matchCompanyPath, matchPath } from 'utils/helpers/pathMatch';

import Spinner from 'components/Spinner/Spinner';
import UpgradeFreemiumChip from 'containers/MasterPage/MenuDrawer/CompanyMenuItems/UpgradeFreemiumChip';
import { GetGuidesMenuList } from '../../../Guides/GuidesIndex';
import IsMarketplaceProvider from '../../../Marketplace/common/IsMarketplaceProvider';
import { SubMenuList } from './MenuSubItem/MenuSubItem';

const CompanyMenuItems = ({
	menuItemFilter,
	pathname,
	companyId,
	betweenSmLg,
	isDrawerExpanded,
	isLicenseExpired,
	isAgreement,
	isAdmin,
	isAdvanced,
	isFreemium,
	onClose,
	localCompanyId,
	isSearchPage,
	userName,
	toggleFinancialPlanContent,
	isProjectsSidebarOpen,
	mock,
	projects,
	isProjectsLoaded,
	smDown,
	isAuthenticated,
	isContributor,
	classes,
	isFinancialPlanLoaded,
	hasFinancialPlan,
	isMainContentMounted,
	openFinancialPlanContent,
	companyName,
	openAdminView,
}) => {
	const intl = useIntl();
	const dispatch = useDispatch();

	// popover
	const [anchorEl, setAnchorEl] = useState(null);
	const [dashboardSelected, setDashboardSelected] = useState(
		matchPath('/', location) || matchPath('/guides', location) || matchPath('/guides/:guideId', location)
	);
	const open = Boolean(anchorEl);
	const handlePopoverOpen = event => setAnchorEl(event.currentTarget);
	const handlePopoverClose = () => setAnchorEl(null);

	return (
		<>
			{/* COMPANY NAME / LINK TO ADMIN VIEW*/}
			{regexCompany.test(pathname) && isDrawerExpanded && (
				<MenuListItem
					button={isAdmin ? true : false}
					onClick={() => (isAdmin ? openAdminView() : null)}
					beige={true}
					conditionalHovered={matchPath('/admin/company', location, false)}
					aria-owns={open ? 'mouse-over-popover' : undefined}
					aria-haspopup='true'
					onMouseEnter={handlePopoverOpen}
					onMouseLeave={handlePopoverClose}
				>
					{companyName ? <ListItemText primary={companyName} /> : <Spinner />}
					{isAdmin && !matchPath('/admin/company', location, false) && (
						<Popover
							id='mouse-over-popover'
							open={open}
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							onClose={handlePopoverClose}
							disableRestoreFocus
							sx={{ pointerEvents: 'none', top: 10, left: 10 }}
						>
							<Typography sx={{ py: 0.5, px: 1, fontSize: '0.9rem' }}>
								{intl.formatMessage({ id: 'admin.link.popover' })}
							</Typography>
						</Popover>
					)}
				</MenuListItem>
			)}
			{/* DASHBOARD */}
			{menuItemFilter && (
				<>
					<MenuListItem
						button
						disabled={pathname === '/license_expired' || isLicenseExpired || !companyName}
						onClick={() => onClose(getCompanyPath('/', pathname, companyId))}
						conditionalStyled={!isDrawerExpanded}
						conditionalHovered={matchPath('/', location)}
					>
						<MenuListItemIcon conditionalStyled={!isDrawerExpanded}>
							<DashboardOutlined />
						</MenuListItemIcon>
						{isDrawerExpanded && (
							<ListItemText primary={<MenuTextItem>{intl.formatMessage({ id: 'menudrawer.dashboard' })}</MenuTextItem>} />
						)}
					</MenuListItem>
				</>
			)}
			{/* GUIDES */}
			{menuItemFilter && (
				<>
					{/* {(matchPath('/', location, true) ||
						matchPath('/guides', location, false) ||
						matchPath('/guides/:guideId', location)) && (
							<ListItemSecondaryAction>
								<IconButton
									onClick={() => setDashboardSelected(!dashboardSelected)}
									className={classes.iconButton}
									style={{
										display: !isDrawerExpanded && 'none',
									}}
								>
									<ListItemIcon classes={{ root: classes.expandIcon }}>
										{dashboardSelected ? <ExpandLess /> : <ExpandMore />}
									</ListItemIcon>
								</IconButton>
							</ListItemSecondaryAction>
						)} */}

					<MenuListItem
						button
						disabled={pathname === '/license_expired' || isLicenseExpired || !companyName}
						onClick={() => onClose(getCompanyPath('/guides', pathname, companyId))}
						conditionalStyled={!isDrawerExpanded}
						conditionalHovered={matchPath('/guides', location, false) || matchPath('/guides/:guideId', location)}
					>
						<MenuListItemIcon conditionalStyled={!isDrawerExpanded}>
							<NewspaperIcon />
						</MenuListItemIcon>
						{isDrawerExpanded && (
							<ListItemText primary={<MenuTextItem>{intl.formatMessage({ id: 'menudrawer.guides' })}</MenuTextItem>} />
						)}
					</MenuListItem>
					{isDrawerExpanded && (
						<Collapse in={dashboardSelected} timeout='auto' unmountOnExit>
							<SubMenuList>
								<GetGuidesMenuList />
							</SubMenuList>
						</Collapse>
					)}
				</>
			)}
			{/* INSTRUMENTS */}
			{menuItemFilter && (
				<MenuListItem
					button
					disabled={pathname === '/license_expired' || isLicenseExpired || !companyName}
					onClick={() => onClose(getCompanyPath('/instruments', pathname, companyId))}
					conditionalStyled={!isDrawerExpanded}
					conditionalHovered={matchPath('/instruments', location, false)}
				>
					<MenuListItemIcon conditionalStyled={!isDrawerExpanded}>
						<BusinessCenterIcon />
					</MenuListItemIcon>
					{isDrawerExpanded && (
						<ListItemText primary={<MenuTextItem>{intl.formatMessage({ id: 'menudrawer.instruments' })}</MenuTextItem>} />
					)}
				</MenuListItem>
			)}
			{/* BUSINESS PLAN */}
			{menuItemFilter && (
				<MenuListItem
					button
					disabled={pathname === '/license_expired' || isLicenseExpired || !companyName}
					onClick={() => onClose(getCompanyPath('/businessplan', pathname, companyId))}
					conditionalStyled={!isDrawerExpanded}
					conditionalHovered={matchPath('/businessplan', location, false)}
				>
					<MenuListItemIcon conditionalStyled={!isDrawerExpanded}>
						<GpsFixedIcon />
					</MenuListItemIcon>
					{isDrawerExpanded && (
						<ListItemText primary={<MenuTextItem>{intl.formatMessage({ id: 'menudrawer.businessPlan' })}</MenuTextItem>} />
					)}
				</MenuListItem>
			)}
			{/* ANALYSIS */}
			{(isAgreement || isAdmin) && (localCompanyId || companyId) && (!isAdvanced || regexCompany.test(pathname)) && !isSearchPage && (
				<AnalysisListItem
					onClose={onClose}
					pathname={pathname}
					companyId={companyId}
					isDrawerExpanded={isDrawerExpanded}
					userName={userName}
					disabled={pathname === '/license_expired' || isLicenseExpired || !companyName}
				/>
			)}
			{/* PROJECTS */}
			{menuItemFilter && (
				<AdminContentContainer>
					<MenuListItem
						button
						disabled={pathname === '/license_expired' || isLicenseExpired || isFreemium || !companyName}
						onClick={() => onClose(getCompanyPath('/projects', pathname, companyId))}
						conditionalStyled={!isDrawerExpanded}
						conditionalHovered={matchPath('/projects', location, false)}
					>
						<MenuListItemIcon conditionalStyled={!isDrawerExpanded}>
							<FolderOutlined />
						</MenuListItemIcon>
						{isDrawerExpanded && (
							<ListItemText primary={<MenuTextItem>{intl.formatMessage({ id: 'menudrawer.projects' })}</MenuTextItem>} />
						)}
						{matchPath('/projects', location, false) &&
							isDrawerExpanded &&
							isProjectsLoaded &&
							!mock &&
							projects &&
							!!Object.keys(projects).length && (
								<ListItemSecondaryAction>
									<IconButton
										onClick={() => dispatch(toggleProjectsSidebar(!isProjectsSidebarOpen))}
										className={classes.iconButton}
									>
										<ListItemIcon classes={{ root: classes.expandIcon }}>
											{isProjectsSidebarOpen ? <ExpandLess /> : <ExpandMore />}
										</ListItemIcon>
									</IconButton>
								</ListItemSecondaryAction>
							)}
					</MenuListItem>
					{isDrawerExpanded && !mock && projects && Object.keys(projects).length && (
						<Collapse in={isProjectsSidebarOpen} timeout='auto' unmountOnExit>
							<ProjectsSidebarContent loading={!isProjectsLoaded} projects={projects} />
						</Collapse>
					)}
				</AdminContentContainer>
			)}
			{/* FINANCIAL PLAN */}
			{menuItemFilter && (
				<>
					<MenuListItem
						button
						disabled={pathname === '/license_expired' || isLicenseExpired || isFreemium || !companyName}
						onClick={() =>
							isFreemium
								? dispatch(openDialog({ name: 'FreemiumCTADialog' }))
								: onClose(getCompanyPath('/financialplan', pathname, companyId))
						}
						conditionalStyled={!isDrawerExpanded}
						conditionalHovered={matchPath('/financialplan', location) || matchPath('/financialplan/edit', location)}
						secondaryAction={isFreemium && isDrawerExpanded && <UpgradeFreemiumChip />}
					>
						<MenuListItemIcon conditionalStyled={!isDrawerExpanded}>
							<ViewTimelineOutlinedIcon />
						</MenuListItemIcon>
						{isDrawerExpanded && (
							<>
								<ListItemText
									primary={
										<MenuTextItem>
											{hasFinancialPlan
												? intl.formatMessage({ id: 'menudrawer.financialplan' })
												: intl.formatMessage({ id: 'menudrawer.financialplan.timeline' })}
										</MenuTextItem>
									}
								/>
							</>
						)}
						{(matchPath('/financialplan', location, false) || matchPath('/financialplan/edit', location, false)) &&
							isFinancialPlanLoaded &&
							isMainContentMounted && (
								<ListItemSecondaryAction>
									<IconButton
										onClick={toggleFinancialPlanContent}
										className={classes.iconButton}
										style={{
											display: !isDrawerExpanded && 'none',
										}}
									>
										<ListItemIcon classes={{ root: classes.expandIcon }}>
											{openFinancialPlanContent ? <ExpandLess /> : <ExpandMore />}
										</ListItemIcon>
									</IconButton>
								</ListItemSecondaryAction>
							)}
					</MenuListItem>
					{isDrawerExpanded && (
						<Collapse in={openFinancialPlanContent} timeout='auto' unmountOnExit>
							<FinancialPlanSidebarContent />
						</Collapse>
					)}
				</>
			)}
			{/* MARKETPLACE */}
			{(isAgreement || companyId) && (
				<IsMarketplaceProvider adminOverride override={companyId}>
					{isMarketplaceProvider =>
						isMarketplaceProvider && (
							<MenuListItem
								button
								disabled={
									pathname === '/license_expired' || isLicenseExpired || isFreemium || (!companyName && !isAdvanced)
								}
								onClick={() =>
									isFreemium
										? dispatch(openDialog({ name: 'FreemiumCTADialog' }))
										: onClose(getCompanyPath('/marketplace', pathname, companyId))
								}
								conditionalStyled={!isDrawerExpanded}
								conditionalHovered={matchPath('/marketplace', location, false)}
								secondaryAction={isFreemium && isDrawerExpanded && <UpgradeFreemiumChip />}
							>
								<MenuListItemIcon conditionalStyled={!isDrawerExpanded}>
									<StorefrontIcon />
								</MenuListItemIcon>
								{isDrawerExpanded && (
									<ListItemText
										primary={<MenuTextItem>{intl.formatMessage({ id: 'menudrawer.marketplace' })}</MenuTextItem>}
									/>
								)}
							</MenuListItem>
						)
					}
				</IsMarketplaceProvider>
			)}
			{/* PROFILE SHARE */}
			{menuItemFilter && (
				<MenuListItem
					button
					disabled={pathname === '/share' || isLicenseExpired || isFreemium || !companyName}
					onClick={() =>
						isFreemium
							? dispatch(openDialog({ name: 'FreemiumCTADialog' }))
							: onClose(getCompanyPath('/share', pathname, companyId))
					}
					conditionalStyled={!isDrawerExpanded}
					conditionalHovered={matchPath('/share', location, false)}
					secondaryAction={isFreemium && isDrawerExpanded && <UpgradeFreemiumChip />}
				>
					<MenuListItemIcon conditionalStyled={!isDrawerExpanded}>
						<ShareIcon />
					</MenuListItemIcon>
					{isDrawerExpanded && (
						<ListItemText primary={<MenuTextItem>{intl.formatMessage({ id: 'menudrawer.share' })}</MenuTextItem>} />
					)}
				</MenuListItem>
			)}
			{/* MESSAGES */}
			<MessagesItem
				disabled={pathname === '/license_expired' || isLicenseExpired || isFreemium || !companyName}
				{...{
					companyId,
					localCompanyId,
					pathname,
					isDrawerExpanded,
					isFreemium,
					isAuthenticated,
					isAgreement,
					isAdmin,
					isAdvanced,
					regexCompany,
					smDown,
					betweenSmLg,
					onClose,
				}}
			/>
			{/* SLIDESHOW */}
			{isAuthenticated &&
				isContributor &&
				(matchCompanyPath(location) ||
					matchPath('/admin/company', location, false) ||
					(isAdmin &&
						!matchPath('/admin', location, false) &&
						!isSearchPage &&
						pathname !== '/user' &&
						pathname !== '/not_found' &&
						pathname !== '/nocompany' &&
						pathname !== '/pricing' &&
						pathname !== '/agreement')) && (
					<MenuListItem
						button
						disabled={pathname === '/license_expired' || isLicenseExpired || !companyName}
						onClick={() => onClose('/slideshow')}
						conditionalStyled={!isDrawerExpanded}
					>
						<MenuListItemIcon conditionalStyled={!isDrawerExpanded}>
							<SlideshowIcon />
						</MenuListItemIcon>
						{isDrawerExpanded && (
							<ListItemText primary={<MenuTextItem>{intl.formatMessage({ id: 'menudrawer.slideshow' })}</MenuTextItem>} />
						)}
					</MenuListItem>
				)}
		</>
	);
};

export default CompanyMenuItems;
