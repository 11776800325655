import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useIntl } from 'react-intl';

import { Typography } from '@mui/material';
import CurrencyValue from 'components/Currency/CurrencyValue';
import { Instrument, Project, ProjectCost, ProjectTask } from 'types/dataTypes';
import { percentFormatterWithFractionDigits } from '../../../../../../utils/formatters/numberFormatters';
import ProjectPrintTableCell from '../ProjectPrintTableItems/ProjectPrintTableCell';
import ProjectPrintTableCellTitle from '../ProjectPrintTableItems/ProjectPrintTableCellTitle';
import { calculateFlatrateSum, parseCosts } from './utils';

const CostsTable = ({
	project,
	tasks,
	costs,
	flatRatePercent,
	flatRateInvestment,
	instrument,
}: {
	project: Project;
	tasks: ProjectTask[];
	costs: ProjectCost[];
	flatRatePercent: number;
	flatRateInvestment: number;
	instrument: Instrument | null | undefined;
}) => {
	const intl = useIntl();

	const costSums = parseCosts(costs);

	const personnelCostPercent = costSums.personnelSideCosts / costSums.salary;

	const flatrateDevelopmentSum = calculateFlatrateSum(project, instrument, flatRatePercent, costSums, 'development');
	const flatrateInvestmentSum = calculateFlatrateSum(project, instrument, flatRateInvestment, costSums, 'investment');

	return (
		<TableContainer
			sx={{
				background: '#fff',
				'@media print': {
					overflowX: 'visible',
					pageBreakInside: 'avoid',
				},
			}}
		>
			<Table>
				<TableBody>
					<TableRow>
						<ProjectPrintTableCellTitle rowHeader={true} tableColumnHeader={true}>
							{intl.formatMessage({ id: 'project.cost.estimate' })}
						</ProjectPrintTableCellTitle>
						<ProjectPrintTableCellTitle rowHeader={true} tableColumnHeader={true} sx={{ textAlign: 'right' }}>
							{intl.formatMessage({ id: 'project.cost.amount' })}
						</ProjectPrintTableCellTitle>
					</TableRow>
					<TableRow>
						<ProjectPrintTableCell>{intl.formatMessage({ id: 'project.cost.salary' })}</ProjectPrintTableCell>
						<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
							{costSums.salary ? <CurrencyValue value={costSums.salary} /> : '-'}
						</ProjectPrintTableCell>
					</TableRow>
					<TableRow>
						<ProjectPrintTableCell>{intl.formatMessage({ id: 'project.cost.personnel.costs' })}</ProjectPrintTableCell>
						<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
							{costSums.personnelSideCosts ? <CurrencyValue value={costSums.personnelSideCosts} /> : '-'}
						</ProjectPrintTableCell>
					</TableRow>
					<TableRow>
						<ProjectPrintTableCell>
							{intl.formatMessage({ id: 'project.cost.personnel.costs.fromsalary' })}
						</ProjectPrintTableCell>
						<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
							{costSums.personnelSideCosts ? (
								<div>{percentFormatterWithFractionDigits.format(personnelCostPercent)}</div>
							) : (
								'-'
							)}
						</ProjectPrintTableCell>
					</TableRow>
					{/* <TableRow>
						<ProjectPrintTableCell>{intl.formatMessage({ id: 'project.cost.purchased.services' })}</ProjectPrintTableCell>
						<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
							{taskCosts.expenses ? <CurrencyValue value={taskCosts.expenses} /> : '-'}
						</ProjectPrintTableCell>
					</TableRow> */}
					{Object.keys(costSums.costByCategory.categories).map(key => (
						<TableRow key={key}>
							<ProjectPrintTableCell>{intl.formatMessage({ id: `project.cost.categories.${key}` })}</ProjectPrintTableCell>
							<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
								<CurrencyValue value={costSums.costByCategory.categories[key]} />
							</ProjectPrintTableCell>
						</TableRow>
					))}
					<TableRow>
						<ProjectPrintTableCell>
							{intl.formatMessage(
								{ id: 'project.cost.table.other.flatrate.title' },
								{
									percent: percentFormatterWithFractionDigits.format(flatrateDevelopmentSum / (project.projectSize ?? 0)),
								}
							)}
						</ProjectPrintTableCell>
						<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
							{flatrateDevelopmentSum ? <CurrencyValue value={flatrateDevelopmentSum} /> : '-'}
						</ProjectPrintTableCell>
					</TableRow>

					{flatrateInvestmentSum > 0 && (
						<TableRow>
							<ProjectPrintTableCell>
								{intl.formatMessage(
									{ id: 'project.cost.flatrate.investment' },
									{ percent: percentFormatterWithFractionDigits.format(flatRateInvestment / 100) }
								)}
							</ProjectPrintTableCell>
							<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
								<CurrencyValue value={flatrateInvestmentSum} />
							</ProjectPrintTableCell>
						</TableRow>
					)}

					<TableRow>
						<ProjectPrintTableCell lastColumn={true}>
							<Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>
								{intl.formatMessage({ id: 'project.cost.total' })}
							</Typography>
						</ProjectPrintTableCell>
						<ProjectPrintTableCell sx={{ textAlign: 'right' }} lastColumn={true}>
							<Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>
								{costSums.sum > 0 ? (
									<CurrencyValue value={costSums.sum + flatrateDevelopmentSum + flatrateInvestmentSum} />
								) : (
									'-'
								)}
							</Typography>
						</ProjectPrintTableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default CostsTable;
