import { useHistory } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import { generateMrrMonths } from '../CSVDownload/CSVGenerator';
import MRRCompanyLicenseGrid from './MRRCompanyLicenseGrid/MRRCompanyLicenseGrid';

const MRRLicenseTable = ({ data, loading = false }: any) => {
	const history = useHistory();

	if (!data) return null;

	const months = generateMrrMonths();

	let licenses: any[] = [];

	data.mrrByCompany.map((company: any) => {
		const licenseArr = company.licenses.map((license: any) => Object.assign({}, license.license, { months: license.months }));

		licenses = [...licenses, ...licenseArr];
	});

	const selectLicenseById = (licenseId: any) => licenses.find(license => license.rowKey === licenseId);
	const fetchCompanyDetails = (companyId: any) => history.push(`/admin/company/${companyId}`);

	const extendedData = licenses?.map(item => {
		const license = selectLicenseById(item.rowKey);

		return { ...item, ...license };
	});

	const columns = [];

	for (let index = 0; index < months.length; index++) {
		const month = months[index];

		columns.push({
			field: month,
			//headerName: month,
			width: 65,
			renderHeader: () => {
				<Typography sx={{ fontSize: '.4rem' }}>sas</Typography>;
			},
			renderCell: (params: any) => {
				return (
					<Typography
						component='div'
						sx={{
							display: 'flex',
							alignIems: 'center',
							justifyContent: 'center',
							height: '100%',
							width: '100%',
							background: params.row.months[month] ? '#90EE90' : 'transparent',
							textAlign: 'center',
							verticalAlign: 'middle',
						}}
					>
						<span style={{ alignSelf: 'center' }}>
							{!params.row.months[month] ? '' : parseFloat(params.row.months[month].value).toFixed(2)}
						</span>
					</Typography>
				);
			},
			cellClassName: 'nopadding-cell',
		});
	}

	return (
		<Box
			sx={{
				'& .nopadding-cell': {
					backgroundColor: 'rgba(255, 255, 255, 0.55)',
					color: '#1a3e72',
					fontWeight: '600',
					padding: '0 !important',
					margin: 0,
				},
			}}
		>
			{/* @ts-ignore */}
			<MRRCompanyLicenseGrid
				rowData={extendedData}
				loading={loading}
				rowClick={(params: any) => fetchCompanyDetails(params.row.companyId)}
				extraColumns={columns}
			/>
		</Box>
	);
};

export default MRRLicenseTable;
