import { calculateExpenseAmount } from 'containers/Projects/Project/Costs/ProjectCostForm/ProjectCostForm';
import { projectCostSelector } from 'containers/Projects/slices';
import { costTaskSelector } from 'containers/Projects/utils';
import { forEach } from 'lodash';
import { Instrument, MainCostCategoryEnum, Project, ProjectCost, ProjectTask, costCategoryArray } from 'types/dataTypes';

export type CostSums = {
	expenses: number;
	salary: number;
	personnelSideCosts: number;
	sum: number;
	other: number;
	personnelCostPercent: number;
	costByCategory: {
		categories: { [k: string]: any };
		investments: number;
		development: number;
		other: number;
	};
};

const exceptions = ['170fc21a-6b72-4e14-92aa-b750aed8df3c', 'fbefc815-986e-4337-ad31-22fc0e1ea433']; // T&K laina / avustus

/*
 * Calculates the instrument flat rate expenses by id and category

 * @param instrument Instrument item
 * @param taskCosts costs array
 * @param categoryType category type
 * @returns
 */
export const calculateInstrumentFlatRateCostExpenses = (
	instrument: Instrument | null | undefined,
	costSums: CostSums,
	categoryType: MainCostCategoryEnum
) => {
	let expenses =
		categoryType === 'development'
			? costSums.costByCategory.development
			: categoryType === 'investment'
			? costSums.costByCategory.investments
			: costSums.costByCategory.other;

	if (instrument && exceptions.find(item => item === instrument.id) && categoryType === 'development') {
		expenses = expenses + costSums.personnelSideCosts;
	}

	return expenses;
};

export const parseCosts = (costs: ProjectCost[]) => {
	let costSums: CostSums = {
		expenses: 0,
		salary: 0,
		personnelSideCosts: 0,
		sum: 0,
		other: 0,
		personnelCostPercent: 0,
		costByCategory: {
			categories: {},
			investments: 0,
			development: 0,
			other: 0,
		},
	};

	forEach(costs, ({ category, amountApplied, salaryExpense, salaryExpenseType, workEstimate }) => {
		const mainCategory = costCategoryArray.find(item => item.value === category);
		if (category === 'salary') {
			costSums.salary += amountApplied * (workEstimate ?? 0); //@ts-ignore
			costSums.personnelSideCosts += calculateExpenseAmount(salaryExpenseType, salaryExpense, amountApplied) * (workEstimate ?? 0);
			costSums.costByCategory.development += amountApplied * (workEstimate ?? 0);
			// calculateExpenseAmount(salaryExpenseType, salaryExpense, amountApplied) * (workEstimate ?? 0);
		} else if (mainCategory?.mainCategory === 'development') {
			costSums.costByCategory.development += amountApplied;
		} else if (mainCategory?.mainCategory === 'investment') {
			costSums.costByCategory.investments += amountApplied;
		} else {
			costSums.costByCategory.other += amountApplied;
		}

		if (category !== 'salary') {
			costSums.expenses += amountApplied;

			if (!costSums.costByCategory.categories[category ?? 'other']) {
				costSums.costByCategory.categories[category ?? 'other'] = 0;
			}

			costSums.costByCategory.categories[category ?? 'other'] += amountApplied;
		}

		costSums.sum = costSums.salary + costSums.personnelSideCosts + costSums.expenses;
	});

	costSums.personnelCostPercent = costSums.personnelSideCosts / costSums.salary;

	return costSums; //{ salary, personnelSideCosts, expenses, sum, investments, development };
};

export const parseTasksCosts = (tasks: any, costs: ProjectCost[]) => {
	let taskSums: CostSums = {
		expenses: 0,
		salary: 0,
		personnelSideCosts: 0,
		sum: 0,
		other: 0,
		costByCategory: {
			categories: {},
			investments: 0,
			development: 0,
			other: 0,
		},
		personnelCostPercent: 0,
	};

	forEach(tasks, (task: ProjectTask) => {
		const taskCosts: ProjectCost[] | null = costTaskSelector(costs, task?.projectTaskId);

		if (!task || !taskCosts) return;

		const cost = parseCosts(taskCosts);

		Object.keys(cost.costByCategory.categories).forEach(key => {
			if (!taskSums.costByCategory.categories[key]) {
				taskSums.costByCategory.categories[key] = 0;
			}

			taskSums.costByCategory.categories[key] += cost.costByCategory.categories[key];
		});

		taskSums.expenses += cost.expenses;
		taskSums.salary += cost.salary;
		taskSums.personnelSideCosts += cost.personnelSideCosts;
		taskSums.expenses += cost.expenses;
		taskSums.other += cost.salary + cost.expenses;
		taskSums.sum += cost.sum;
		taskSums.costByCategory.investments += cost.costByCategory.investments;
		taskSums.costByCategory.development += cost.costByCategory.development;
		taskSums.costByCategory.other += cost.costByCategory.other;
	});

	return taskSums;
};

export const calculateFlatrateSum = (
	project: Project,
	instrument: Instrument | null | undefined,
	flatRatePercent: number,
	taskCosts: CostSums,
	categoryType: MainCostCategoryEnum
) => {
	const expenses = calculateInstrumentFlatRateCostExpenses(instrument, taskCosts, categoryType);

	if (categoryType === 'development') {
		return (expenses ?? 0) * (flatRatePercent / 100);
	}

	return expenses * (flatRatePercent / 100);
};

export const calculateOptimalFlatrateSum = (
	project: Project,
	categoryType: MainCostCategoryEnum,
	instrument: Instrument | null | undefined
) => {
	const costs: Array<ProjectCost> = projectCostSelector(project);

	const taskCosts = parseCosts(costs);

	let expenses =
		categoryType === 'development'
			? taskCosts.costByCategory.development
			: categoryType === 'investment'
			? taskCosts.costByCategory.investments
			: taskCosts.costByCategory.other;

	const projectSize = project.projectSize ?? 0;

	const costSums = parseCosts(costs);

	let missingSum =
		projectSize - taskCosts.sum - calculateFlatrateSum(project, instrument, project.flatRateInvestment ?? 0, costSums, 'investment');

	if (instrument && exceptions.find(item => item === instrument.id)) {
		missingSum = projectSize - (expenses + taskCosts.personnelSideCosts);
	}

	const missingFlatRatePercent = (missingSum / (expenses ?? 0)) * 100;

	return { missingSum, missingFlatRatePercent };
};
