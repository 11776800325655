import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import CancelIcon from '@mui/icons-material/Cancel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Grid, Stack, Tooltip } from '@mui/material';
import Button from 'components/Button/Button';
import ConfirmButton from 'components/Button/ConfirmButton';
import ModalDialog from 'components/Dialog/ModalDialog';
import { FormControlledCurrencyInput, FormControlledDatepicker, FormControlledInput, FormControlledSelect } from 'components/Form';
import FromControlledHiddenField from 'components/Form/FromControlledHiddenField';
import FormatCurrency from 'components/Formatters/FormatCurrency';
import PanelTitle from 'components/Titles/PanelTitle';
import { toast } from 'react-toastify';
import FeatureList from './FeatureList';
import licenseSchema from './licenseSchema';

// @ts-nocheck

type Props = {
	license: any;
	isOpen: boolean;
	handleClose: () => void;
	saveLicense: (license: any, silently: boolean) => void;
	terminateCompanyLicense: () => boolean;
	company: any;
	loading: boolean;
	contractBillingPeriodTypes: Array<string>;
	contractPeriodTypes: Array<string>;
	contractBillingTypes: Array<string>;
	licenseTypes: Array<string>;
	contractTypes: Array<string>;
	licensePackages: Array<string>;
};

const LicenseMonthlyValue: React.FC<any> = ({ intl, contractMonetaryValue, contractPeriodMonth, contractMonthlyValue }) => {
	const monthlyValueIsSet = contractMonetaryValue && contractPeriodMonth;
	const monthlyValue = monthlyValueIsSet ? contractMonetaryValue / contractPeriodMonth : contractMonthlyValue;

	if (!monthlyValue) return null;

	return (
		<>
			{intl.formatMessage({ id: 'license.form.contractMonetaryValue.month' })}
			<FormatCurrency value={monthlyValue} maximumFractionDigits={2} />
		</>
	);
};

const LicenseModalForm: React.FC<Props> = ({
	license,
	isOpen,
	handleClose,
	saveLicense,
	terminateCompanyLicense,
	company,
	loading,
	contractBillingPeriodTypes = [],
	contractPeriodTypes = [],
	contractBillingTypes = [],
	licenseTypes = [],
	contractTypes = [],
	licensePackages = [],
}) => {
	const intl = useIntl();
	const formRef = useRef();

	const schema = licenseSchema(intl);

	const {
		control,
		formState: { errors },
		handleSubmit,
		reset,
		watch,
		setValue,
	} = useForm({
		defaultValues: {
			license,
		},
		resolver: yupResolver(schema),
	});

	const terminateLicense = async () => {
		const ret = await terminateCompanyLicense();

		if (!ret) {
			toast.error(intl.formatMessage({ id: 'license.form.termination.failed' }));
			return;
		}

		toast.error(intl.formatMessage({ id: 'license.form.termination.success' }));

		handleClose();
	};

	const onSubmit = async (data: object, silently: boolean) => {
		const ret = await saveLicense(data, silently);

		// await save on parent and don't close if error
		// @ts-ignore
		if (ret.error) return;

		handleClose();
		reset();
	};
	// @ts-ignore
	const submitForm = () => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
	// @ts-ignore
	const contractBillingPeriod = watch('contractBillingPeriod');

	useEffect(() => {
		// @ts-ignore
		if (contractBillingPeriod !== 'other') {
			const values = {
				year: 12,
				half: 6,
				monthly: 1,
				quarter: 3,
				other: 1,
			};
			// @ts-ignore
			const billingDuration = values[contractBillingPeriod] ?? 0;
			// @ts-ignore
			setValue('contractBillingPeriodMonth', billingDuration);
		} else {
			// @ts-ignore
			setValue('contractBillingPeriodMonth', license?.contractBillingPeriodMonth ?? 0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contractBillingPeriod]);
	// @ts-ignore
	const contractPeriod = watch('contractPeriod');
	// @ts-ignore
	const contractPeriodMonth = watch('contractPeriodMonth');
	// @ts-ignore
	const contractMonetaryValue = watch('contractMonetaryValue');
	useEffect(() => {
		// @ts-ignore
		if (contractPeriod !== 'other') {
			const values = {
				year: 12,
				half: 6,
				monthly: 1,
				quarter: 3,
				other: 1,
			};
			// @ts-ignore
			const contractDuration = values[contractPeriod] ?? 0;
			// @ts-ignore
			setValue('contractPeriodMonth', contractDuration);
		} else {
			// @ts-ignore
			setValue('contractPeriodMonth', license?.contractPeriodMonth ?? 0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contractPeriod]);

	useEffect(() => {
		// ensure that values are set in the form when it is opened
		if (isOpen) {
			reset(license);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const isUpdate = license.rowKey ? true : false;

	return (
		<ModalDialog
			open={isOpen}
			// @ts-ignore
			onClose={handleClose}
			// @ts-ignore
			title={intl.formatMessage({ id: 'license.form.title' })}
			// @ts-ignore
			actions={
				<Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
					<Box sx={{ flexGrow: 1, display: 'flex' }}>
						{/* 
						// @ts-ignore */}
						<Button variant='text' onClick={handleClose} loading={loading}>
							{intl.formatMessage({ id: 'shared.close' })}
						</Button>
						{/* 
						// @ts-ignore */}
						<ConfirmButton
							sx={{ display: !license || !license.isActive ? 'none' : 'flex', mr: '1rem' }}
							//variant='text'
							color='error' // @ts-ignore
							startIcon={<CancelIcon size='small' />} // @ts-ignore
							confirmAction={terminateLicense}
							confirmText={intl.formatMessage({ id: 'license.remove.confirm' })}
							cancelText={intl.formatMessage({ id: 'license.remove.cancel' })}
							confirmBodyText={intl.formatMessage({ id: 'license.remove.body' })}
							buttonText={intl.formatMessage({ id: 'license.form.remove' })}
							disabled={!license || !license.isActive}
						/>
					</Box>

					<Box sx={{ display: 'flex' }}>
						{/* 
							// @ts-ignore */}
						<Button color='success' onClick={data => submitForm(data)} loading={loading} sx={{ mr: '1rem' }}>
							{intl.formatMessage({ id: 'shared.save' })}
						</Button>
						{/* 
							// @ts-ignore */}
						{!isUpdate && (
							// @ts-ignore
							<Button color='success' onClick={handleSubmit(data => onSubmit(data, true))} loading={loading}>
								{intl.formatMessage({ id: 'shared.save.silently' })}
							</Button>
						)}
					</Box>
				</Stack>
			}
		>
			{/* Form */}
			{/* 
			// @ts-ignore */}
			<form ref={formRef} onSubmit={handleSubmit(data => onSubmit(data, false))}>
				<Grid container spacing={2} sx={{ p: 3 }}>
					<Grid item xs={12} md={6}>
						<FromControlledHiddenField name={'partitionKey'} defaultValue={license?.partitionKey} control={control} />
						<FromControlledHiddenField name={'companyName'} defaultValue={company.companyName} control={control} />
						<FromControlledHiddenField name={'rowKey'} defaultValue={license?.rowKey} control={control} />
						<FormControlledSelect // @ts-ignore
							defaultValue={license?.type ?? licenseTypes[0].value}
							name='type'
							control={control} // @ts-ignore
							error={errors?.type?.message}
							label={intl.formatMessage({ id: 'license.form.type' })}
							options={licenseTypes.map(item => {
								return {
									value: item,
									label: intl.formatMessage({ id: `license.form.licenseType.${item}` }),
								};
							})}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FormControlledSelect // @ts-ignore
							defaultValue={license?.package ?? licensePackages[0].value}
							name='package'
							control={control} // @ts-ignore
							error={errors?.package?.message}
							label={intl.formatMessage({ id: 'license.form.licensePackage' })}
							options={licensePackages.map(item => {
								return {
									value: item,
									label: intl.formatMessage({ id: `license.form.licensePackage.${item}` }),
								};
							})}
						/>
					</Grid>
					<Grid item xs={12}>
						{/* 
						// @ts-ignore */}
						<FeatureList control={control} errors={errors} licenseData={license} />
					</Grid>

					<Grid item xs={12} md={6}>
						{/* 
						// @ts-ignore */}
						<FormControlledDatepicker
							defaultValue={license?.startDate ?? new Date()}
							name='startDate'
							control={control} // @ts-ignore
							error={errors?.startDate?.message}
							label={intl.formatMessage({ id: 'license.form.startDate' })}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						{/* 
						// @ts-ignore */}
						<FormControlledDatepicker
							defaultValue={license?.endDate ?? null}
							name='endDate'
							control={control} // @ts-ignore
							error={errors?.endDate?.message}
							label={intl.formatMessage({ id: 'license.form.endDate' })}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						{/* 
						// @ts-ignore */}
						<FormControlledDatepicker
							defaultValue={license?.mrrContractStartDate ?? null}
							name='mrrContractStartDate'
							control={control} // @ts-ignore
							error={errors?.mrrContractStartDate?.message}
							label={
								<Tooltip title={intl.formatMessage({ id: 'license.form.mrrContractStartDate.tooltip' })}>
									<Stack direction='row' alignItems='center' sx={{ '&:hover': { cursor: 'help' } }}>
										{intl.formatMessage({ id: 'license.form.mrrContractStartDate' })}
										{/* 
										// @ts-ignore */}
										<HelpOutlineIcon sx={{ ml: '1rem' }} size='small' />
									</Stack>
								</Tooltip>
							}
						/>
					</Grid>
					<Grid item xs={12}>
						{/* 
						// @ts-ignore */}
						<PanelTitle sx={{ mt: '1rem' }}>Sopimus</PanelTitle>
					</Grid>
					<Grid item xs={6}>
						<FormControlledSelect
							defaultValue={license?.contractType ?? 'continuous'}
							name='contractType'
							control={control} // @ts-ignore
							error={errors?.contractType?.message}
							label={intl.formatMessage({ id: 'license.form.contractType' })} // @ts-ignore
							options={contractTypes.map(item => {
								return {
									value: item,
									label: intl.formatMessage({ id: `license.form.contractType.${item}` }),
								};
							})}
						/>
					</Grid>
					<Grid item xs={6}>
						<Stack direction='row' spacing={2} alignItems='flex-start'>
							<FormControlledSelect
								defaultValue={license?.contractPeriod ?? 'year'}
								name='contractPeriod'
								control={control} // @ts-ignore
								error={errors?.contractPeriod?.message}
								label={intl.formatMessage({ id: 'license.form.contractPeriodType' })}
								options={contractPeriodTypes.map(item => {
									return {
										value: item,
										label: intl.formatMessage({ id: `license.form.contractPeriodType.${item}` }),
									};
								})}
							/>
							<Box sx={{ width: '30%' }}>
								{/* 
								// @ts-ignore */}
								<FormControlledInput // @ts-ignore
									disabled={contractPeriod ? contractPeriod !== 'other' : license.contractPeriod !== 'other'}
									type='number'
									defaultValue={license?.contractPeriodMonth ?? 12}
									name='contractPeriodMonth'
									control={control} // @ts-ignore
									error={errors?.contractPeriodMonth?.message}
									label={intl.formatMessage({ id: 'license.form.contractPeriodMonthDuration' })}
								/>
							</Box>
						</Stack>
					</Grid>
					<Grid item xs={6}>
						<FormControlledCurrencyInput
							placeholder={intl.formatMessage({ id: 'license.form.contractMonetaryValue.placeholder' })}
							defaultValue={license?.contractMonetaryValue}
							name='contractMonetaryValue'
							control={control} // @ts-ignore
							error={errors?.contractMonetaryValue?.message}
							label={intl.formatMessage({ id: 'license.form.contractMonetaryValue' })}
							allowNegative
						/>
						<LicenseMonthlyValue
							{...{ intl, contractMonetaryValue, contractPeriodMonth, contractMonthlyValue: license?.contractMonthlyValue }}
						/>
					</Grid>

					<Grid item xs={12}>
						{/* 
						// @ts-ignore */}
						<PanelTitle sx={{ mt: '1rem' }}>Laskutus</PanelTitle>
					</Grid>
					<Grid item xs={6}>
						<FormControlledSelect
							defaultValue={license?.contractBillingType ?? 'normal'}
							name='contractBillingType'
							control={control} // @ts-ignore
							error={errors?.contractBillingType?.message}
							label={intl.formatMessage({ id: 'license.form.contractBillingType' })}
							options={contractBillingTypes.map(item => {
								return {
									value: item,
									label: intl.formatMessage({ id: `license.form.contractBillingType.${item}` }),
								};
							})}
						/>
					</Grid>
					<Grid item xs={6}>
						<Stack direction='row' spacing={2} alignItems='flex-start'>
							<FormControlledSelect
								defaultValue={license?.contractBillingPeriod ?? 'year'}
								name='contractBillingPeriod'
								control={control} // @ts-ignore
								error={errors?.contractBillingPeriod?.message}
								label={intl.formatMessage({ id: 'license.form.contractBillingPeriodType' })}
								options={contractBillingPeriodTypes.map(item => {
									return {
										value: item,
										label: intl.formatMessage({ id: `license.form.contractBillingPeriodType.${item}` }),
									};
								})}
							/>
							<Box sx={{ width: '30%' }}>
								{/* 
								// @ts-ignore */}
								<FormControlledInput
									disabled={
										isUpdate || contractBillingPeriod // @ts-ignore
											? contractBillingPeriod !== 'other'
											: license.contractBillingPeriod !== 'other'
									}
									type='number'
									defaultValue={license?.contractBillingPeriodMonth ?? 12}
									name='contractBillingPeriodMonth'
									control={control} // @ts-ignore
									error={errors?.contractBillingPeriodMonth?.message}
									label={intl.formatMessage({ id: 'license.form.billingPeriodMonthlyDuration' })}
								/>
							</Box>
						</Stack>
					</Grid>
					<Grid item xs={12}>
						{/* 
						// @ts-ignore */}
						<FormControlledInput
							defaultValue={license?.salesPerson ?? ''}
							name='salesPerson'
							control={control} // @ts-ignore
							error={errors?.salesPerson?.message}
							label={intl.formatMessage({ id: 'license.form.salesPerson' })}
						/>
					</Grid>
					<Grid item xs={12}>
						{/* 
						// @ts-ignore */}
						<FormControlledInput
							defaultValue={license?.comments ?? ''}
							name='comments'
							rows={5}
							control={control} // @ts-ignore
							error={errors?.comment?.message}
							label={intl.formatMessage({ id: 'license.form.comments' })}
						/>
					</Grid>
					<Grid item xs={12}>
						{/* 
						// @ts-ignore */}
						<FormControlledInput
							defaultValue={license?.payingCustomer ?? ''}
							name='payingCustomer'
							control={control} // @ts-ignore
							error={errors?.payingCustomer?.message}
							label={intl.formatMessage({ id: 'license.form.payingCustomer' })}
						/>
					</Grid>
					<Grid item xs={12}>
						{/* 
						// @ts-ignore */}
						<FormControlledInput
							defaultValue={license?.severaLink ?? ''}
							name='severaLink'
							control={control} // @ts-ignore
							error={errors?.severaLink?.message}
							label={intl.formatMessage({ id: 'license.form.severaLink' })}
						/>
					</Grid>
				</Grid>
				<FromControlledHiddenField name='companyName' defaultValue={license?.companyName ?? ''} control={control} />
			</form>
		</ModalDialog>
	);
};

export default LicenseModalForm;
